import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }

  navigate(parts: string[]) {
    this.router.navigate(parts);
  }

  getDealDeskRoute(): string[] {
    return ['deal-desk'];
  }

  getDealDeskViewContractRoute(quoteRequestId: string): (string | number)[] {
    return [...this.getDealDeskRoute(), 'contract', quoteRequestId];
  }

  getDashboard(): string[] {
    return ['dashboard'];
  }

  getContracts(): string[] {
    return ['contracts'];
  }

  getBidHistory(): string[] {
    return ['bid-history'];
  }
}
